





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        ProductSample: () => import('@/components/product/ProductSample.vue'),
    },
})
export default class ProductSearchDropdown extends Vue {
    @Prop() products?: Product[];
    @Prop() show?: boolean;
}
